// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import axios from 'axios'
import React, { Suspense, lazy } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import '../stylesheets/application'
import { ReactQueryDevtools } from 'react-query/devtools'
import Theme from '../components/molecules/Theme'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import Analytics from '../components/molecules/Analytics'
import ScrollToTop from '../components/molecules/ScrollToTop'
import { QueryClient, QueryClientProvider } from 'react-query'
import '../configs/i18n'
import CitizenNotFound from '../components/pages/citizens/CitizenNotFound'

const CitizenFormPage = lazy(() => import('../components/pages/citizens/CitizenFormPage'))
const TrackingInfo = lazy(() => import('../components/pages/citizens/TrackingInfo'))
const TrackingQuery = lazy(() => import('../components/pages/citizens/TrackingQuery'))
const CitizenQuickSearchPage = lazy(() => import('../components/pages/citizens/CitizenQuickSearchPage'))

require('@rails/activestorage').start()
require('channels')

const csrf = document.querySelector("meta[name='csrf-token']").getAttribute('content')
axios.defaults.headers.common['Content-Type'] = 'application/json'
axios.defaults.headers.common['X-CSRF-Token'] = csrf

const queryClient = new QueryClient()

const App = () => {
  return (
    <Theme>
      <Router>
        <Analytics />
        <ScrollToTop />
        <ReactQueryDevtools initialIsOpen={false} />
        <Switch>
          <Route exact path="/t/:id(\d+)">
            <CitizenFormPage />
          </Route>
          <Route exact path="/seguimiento">
            <TrackingQuery />
          </Route>
          <Route exact path="/t/:formId/:answerHashId">
            <TrackingInfo />
          </Route>
          <Route path="/c/:id(\d+)">
            <CitizenQuickSearchPage />
          </Route>
          <Route path="*">
            <CitizenNotFound />
          </Route>
        </Switch>
      </Router>
    </Theme>
  )
}

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <QueryClientProvider client={queryClient}>
        <Suspense fallback={<div></div>}>
          <App />
        </Suspense>
      </QueryClientProvider>
    </MuiPickersUtilsProvider>,
    document.body.appendChild(document.createElement('div'))
  )
})

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
